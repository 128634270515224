import {server} from "../config";
import {getUserToken} from "./index";
import {validateResponseData} from "./ProcessResponseUtils";

export async function sendRequest(route, method = 'GET', data = {}, additionalHeaders = {}, isBlob = false) {
    const apiBase = server;
    const defaultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const headers = {...defaultHeaders, ...additionalHeaders};
    if (getUserToken()) {
        headers['x-www-access'] = getUserToken();
    }

    const payload = method === 'GET' ? {method, headers} : {method, body: JSON.stringify(data), headers};

    try {
        const response = await fetch(`${apiBase}${route}`, payload);
        const parsedResponseData = isBlob ? await response.blob() : await response.json();
        return validateResponseData(parsedResponseData);
    } catch (error) {
        return _handleRequestError(error);
    }
}

function _handleRequestError(error) {
    console.error(error);
    return {
        code: 404,
        message: "Errors",
        errors: [{"errorCode": 409172, "errorMessage": "Internal Server Error"}],
    };
}
