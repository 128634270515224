import {observer} from "mobx-react-lite";
import {
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    Grid,
    FormControl, InputLabel, Select, Typography
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Image from "mui-image";
import React from "react";
import {
    GridView,
    IntegrationInstructionsOutlined,
    LogoutOutlined,
    PriceChangeOutlined,
    SettingsOutlined
} from "@mui/icons-material";
import {useContext} from "react";
import {AuthContext} from "../../store/auth";

const LeftMenu = observer(()=>{
    const [open, setOpen] = useState(true);
    const auth = useContext(AuthContext)
    const navigate = useNavigate();
    return(

            <List >
                {[
                    {
                    text: "Dashboard",
                        icon: <GridView />,
                        link: "/cabinet/dashboard"
                    },
                    {
                    text: "Transactions",
                        icon: <PriceChangeOutlined />,
                        link: "/soon"
                    },
                    {
                    text: "For developers",
                        icon: <IntegrationInstructionsOutlined />,
                        link: "/soon"
                    },
                    {
                    text: "Settings",
                        icon: <SettingsOutlined />,
                        link: "/soon"
                    },
                    {
                    text: "Log out",
                        icon: <LogoutOutlined />,
                        link: "/",
                        onClick: auth.logOut
                    }
                ].map((listItem)=>(
                    <Grid item xs={12}>
                    <ListItem
                        key={listItem.text}
                        disablePadding>
                        <ListItemButton
                            sx={{justifyContent:'initial'}}
                            onClick={()=>{
                                if(listItem.onClick){
                                    listItem.onClick();
                                    navigate(listItem.link)
                                }else{
                                navigate(listItem.link)
                                }
                            }}>
                            <ListItemIcon sx={{minWidth:'18px',pr:"15px"}}>
                                {listItem.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={listItem.text}
                            />
                        </ListItemButton>
                    </ListItem>
                    </Grid>
                    ))
                }
            </List>

    )
})

export default LeftMenu