import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";

import { AuthLayout } from "./layouts/index";
import { NavigateSetter } from "./utils/NavigationHistoryUtils";
import { authRoutes,cabinetRoutes,commonRoutes } from "./routes";
import Providers from "./Providers";
import {CabinetLayout} from "./layouts";
import {ProtectedRoute} from "./utils/RoutesUtils";

process.env.CI = false;

export const App = () => {
  return (
    <Router>
      <Providers>
        <NavigateSetter />
        <Routes>
          <Route exact path={"/"} element={<Navigate to={"/auth"} replace />} />
          <Route path="/auth" element={<AuthLayout />}>
            {authRoutes.map((route, index) => {
              return route.index ? (
                <>
                  <Route key={`guest-routes-${index}`}
                         index={route.index}
                         element={route.element} />
                  <Route key={`guest-routes-${index}`}
                         path={route.path}
                         element={route.element} />
                </>
              ) : (
                <Route key={`guest-routes-${index}`}
                       path={route.path}
                       element={route.element} />
              );
            })}
          </Route>
          <Route path="/cabinet" element={<Navigate to={"/cabinet/dashboard"} replace />}/>
          <Route path="/cabinet" element={<ProtectedRoute><CabinetLayout/></ProtectedRoute>}>
            {cabinetRoutes.map((route, index) => (
                <Route key={`cabinet-routes-${index}`} index={route.index}
                       path={route.index ? null : route.path} element={
                  <ProtectedRoute>{route.element}</ProtectedRoute>
                }/>
            ))}
          </Route>
          <Route path="" element={<AuthLayout/>}>
          {
            commonRoutes.map((route, index) => (
                <Route key={`common-routes-${index}`} index={route.index}
                       path={route.index ? null : route.path} element={route.element}/>
            ))
          }
          </Route>

        </Routes>
      </Providers>
    </Router>
  );
};

console.log(
  "%c Easysolution",
  "font-weight: bold; font-size: 72px;color: #00ffff; text-shadow: 3px 3px 0 #00ECEC, 6px 6px 0 #80FFFF, 9px 9px 0 #070649, 12px 12px 0 #070649, 15px 15px 0 #070649, 18px 18px 0 #070649, 21px 21px 0 #070649"
);
