import React, {lazy, Suspense} from 'react';

const LazyLoadComponent = (Component) => (props) => (
    <Suspense fallback={
        <></>
    }>
        <Component {...props} />
    </Suspense>
);
const ComingSoon = LazyLoadComponent(lazy(() => import('./common/comingSoon')))
const Login = LazyLoadComponent(lazy(() => import('./Auth/Login/Login')));
const SignUp = LazyLoadComponent(lazy(()=>import('./Auth/signUp/signUp')));
const ConfirmEmail = LazyLoadComponent(lazy(()=>import('./Auth/signUp/confirmEmail')));
const Dashboard = LazyLoadComponent(lazy(() => import('./dashboard/dashboard.page')));
export {
    LazyLoadComponent,
    ComingSoon,
    Login,
    SignUp,
    ConfirmEmail,
    Dashboard
};
