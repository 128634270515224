import {observer} from "mobx-react-lite";
import {Container, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import Image from "mui-image";
import React from "react";
import LeftMenu from "./LeftMenu";

const LeftSidebar = observer(() => {

    return(
        <Grid container mt={0}>
            <Grid item xs={12}>
                <Image src={'/images/logo.svg'} style={{ height: "60px",
                    width: "200px"}}/>
            </Grid>
            <Grid container mt={15} spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel color={'secondary'}>Project</InputLabel>
                        <Select
                            value={1}
                            defaultValue={1}
                        fullWidth
                        >
                            <MenuItem key={1} value={1}>
                                <Typography variant={'h6'}> Shop #1</Typography>
                            </MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={'secondary'}>
                            User role
                        </Typography>
                        <Typography variant={'h6'}>
                            Merchant
                        </Typography>
                    </Grid>
                <LeftMenu/>
                </Grid>
        </Grid>
    )

})

export default LeftSidebar;