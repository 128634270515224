import {alpha} from '@mui/material/styles';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

export function overrides(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          maxWidth: '100%',
          display: 'inline-block',
          verticalAlign: 'bottom',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[900], 0.8),
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: theme.palette.common.white,
          background: `linear-gradient(90deg, ${theme.palette.gradient[0]} 0%, ${theme.palette.gradient[100]} 100%)`,
          '&:hover': {
            color: theme.palette.common.white,
            background: `linear-gradient(90deg, ${theme.palette.gradient[0]} 0%, ${theme.palette.gradient[100]} 100%)`,
          },
        },
        sizeLarge: {
          minHeight: 48,
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        containedInherit: {
          color: theme.palette.common.white,
          background: `linear-gradient(180deg, ${theme.palette.gradient[0]} 0%, ${theme.palette.gradient[100]} 100%)`,
          '&:hover': {
            color: theme.palette.common.white,
            background: `linear-gradient(180deg, ${theme.palette.gradient[0]} 0%, ${theme.palette.gradient[100]} 100%)`,
          },
        },
        sizeLarge: {
          minHeight: 48,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.card,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: alpha(theme.palette.grey[500], 0.24),
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[800],
        },
        arrow: {
          color: theme.palette.grey[800],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          marginTop: theme.spacing(2),
          marginLeft: '32px',
          marginRight: '0px',
          padding: '20px 24px',
          borderRadius: '20px',
        }
      }
    },
    MuiFormControl:{
      styleOverrides:{
        root:{
          label: {
            //color: "#F4F4F4",
            fontFamily: "Roboto-Regular, Helvetica",
            fontSize: "19px",
            transform: "translate(14px, -9px) scale(0.75)",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            left: "-15px",
            top: "-15px",
          },
          fieldset: {legend:{width:"0px"}},
          input:{
            height:'10px'
          },
        }
      }
    },
    MuiTabs:{
      styleOverrides:{
        root:{
          backgroundColor: 'white',
          padding:'6px',
          borderRadius: "11px",
        },
          indicator:{
            display: 'none'
          }
      }
    },
    MuiTab:{
      styleOverrides:{
        root:{
          '&.Mui-selected': {
            // Your custom styles for the selected tab
            backgroundColor: theme.palette.gradient[200],
            color: '#4F4F4F',
            borderRadius: "11px",
          },
        }
      }
    },
    MuiSelect:{
      styleOverrides:{
        root:{
          borderRadius: "11px",
          border: "1px solid rgba(189, 189, 189, 0.33)",
          background: "#F3F3F3",
          fieldset: {legend:{width:"0px"}},

        }
      }
    },

    MuiCheckbox:{
      styleOverrides:{
        root:{
          svg:{
            color:'#C4C4C4'
          }
        }
      }
    },
    MuiTextField:{
      styleOverrides: {
        root:{
          '&:-webkit-autofill':{backgroundColor:'#F3F3F3'},
          borderRadius: "11px",
          border: "1px solid rgba(189, 189, 189, 0.33)",
          background: "#F3F3F3",
          input:{
            height:'9px'
          },
          fieldset: {legend:{width:"0px"}},
          label:{
            color: "#4F4F4F",
            fontFamily: "Roboto-Regular, Helvetica",
            fontSize: "19px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            left: "-15px",
            top: "-15px",
          },
        },
      }
    },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: "#4F4F4F",
    //       fontFamily: "Roboto",
    //       fontSize: "24px",
    //       fontStyle: "normal",
    //       fontWeight: 400,
    //       lineHeight: "normal",
    //       left: "-10px",
    //       top: "-20px",
    //     },
    //   }
    // }
  };
}
