import {makeAutoObservable} from 'mobx';
import AuthService from "../services/auth.service";
import {createContext} from "react";
import {setUserInfoToLocalStorage} from "../utils";

class Auth {
    user = null;
    errors = [];

    constructor() {
        makeAutoObservable(this);
    }

    signUp = async (data) => {
        const response = await AuthService.signUp(data);
        if(!response.status) this.errors = response
        return response
    }

    signIn = async (data) => {

        const response = await AuthService.signIn(data);
        if(response.status) {

            this.user = response
            setUserInfoToLocalStorage(response.user,response.session);
        }
            this.errors = response
        return response
    }

    logOut = async () => {
        localStorage.removeItem("user");
    }
}

export default new Auth();
export const AuthContext = createContext(new Auth())
