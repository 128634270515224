const {REACT_APP_NODE_ENV: env} = process.env;
const baseProdServer = 'api.easysolution.tech';
const baseDevServer = 'api.easysolution.tech';
const baseLocalServer = '127.0.0.1:5010';

const config = {
    development: {
        apiServer: `https://${baseDevServer}/v1`,
        wsServer: `ws://46.4.103.79:4010`,
    },
    production: {
        apiServer: `https://${baseProdServer}/v1`,
        wsServer: `ws://188.40.140.21:4010`,
    },
    local: {
        apiServer: `http://${baseLocalServer}/v1`,
        wsServer: `ws://127.0.0.1:4010`,
    },
};

const getEnvironmentConfig = (env) => {
    return config[env] || config.local;
};

const { apiServer: server, wsServer } = getEnvironmentConfig(env);

export { server, wsServer, env };
