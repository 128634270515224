import React from "react";
import {ConfirmEmail, Login, SignUp} from "../pages";
import {NoProtectedRoute} from 'utils/RoutesUtils';

const authRoutes = [
    {
        path: "login",
        element: (
            <NoProtectedRoute>
                <Login/>
            </NoProtectedRoute>
        ),
        index: true,
    },
    {
        path: "register",
        element: (
            <NoProtectedRoute>
                <SignUp/>
            </NoProtectedRoute>
        ),
        index: false,
    },
    {
        path: "confirm",
        element: (
            <NoProtectedRoute>
                <ConfirmEmail/>
            </NoProtectedRoute>
        ),
        index: false,
    },
];

export default authRoutes;
