import React, {createContext, useContext} from 'react';
import {SnackbarProvider} from 'notistack';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {getActiveLanguageFromLS, setActiveLanguageToLS} from "./utils";
import ThemeProvider from "./theme";
import auth from "./store/auth";
import {SnackbarUtilsConfigurator} from "./utils/SnackBarUtils";

export const StoreContext = createContext();

const userLanguage = getActiveLanguageFromLS();
if (!userLanguage) setActiveLanguageToLS("eng");

const store = {
    auth,
};

export function useStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useStore must be used within StoreContext.Provider");
    }

    return context;
}

const Providers = ({children}) => (
    <StoreContext.Provider value={store}>
        <ThemeProvider>

            <SnackbarProvider maxSnack={3}>
                <SnackbarUtilsConfigurator/>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {children}
                </LocalizationProvider>
            </SnackbarProvider>
        </ThemeProvider>
    </StoreContext.Provider>
);

export default Providers;
