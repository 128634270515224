import {sendRequest} from "../utils/send-request";
import {getUserToken} from "../utils";

class AuthService{

    static async signUp(data) {
        const res = sendRequest(
            '/auth/register',
            'POST',
                data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    static async signIn(data) {
        const res = sendRequest(
            '/auth/login',
            'POST',
                data,
            {
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }
    static async logOut(data) {
        const res = sendRequest(
            '/auth/logout',
            'POST',
                data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-www-access': getUserToken()
            }
        );
        return res;
    }

}

export default AuthService;