import React from 'react';
import {Outlet} from 'react-router-dom';
import {Grid} from "@mui/material";
import Image from "mui-image";

const AuthLayout = () => {
    return (
        <Grid container height={'100vh'}>
            <Grid item xs={8} sx={{
                backgroundImage:"url('/images/general.svg')",
                backgroundSize:"cover",
                }}>
                <Image src={'/images/logo.svg'} style={{ height: "60px",
                    left: "31px",
                    position: "absolute",
                    top: "37px",
                    width: "200px"}}/>
            </Grid>
            <Grid item xs={4}>
                <Grid container
                      justifyContent={'center'}
                      alignContent={'center'}
                      height={'100vh'}>
                    <Grid container width={'60%'} justifyContent={'left'} alignContent={'left'}>
                            <Outlet/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AuthLayout;
