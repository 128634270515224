export const setUserInfoToLocalStorage = (user,session) => {
  if (user === null) {
    localStorage.removeItem("user");
    return;
  }
  const userInfo = {
    userName: user.first_name + " " + user.last_name,
    userAvatar: user.avatar,
    userToken: session.token,
    userType: user.type,
    userId: user.id,
  };

  localStorage.setItem("user", JSON.stringify(userInfo));
};


export const getActiveLanguageFromLS = () => {
  return localStorage.getItem("language") || "eng";
};

export const setActiveLanguageToLS = (language) => {
  localStorage.setItem("language", language);
};

export const getUserInfoFromLocalStorage = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const getUserToken = () => {
  const user = getUserInfoFromLocalStorage();
  return user ? user.userToken : null;
};

export const removeEmptyPropertiesFromObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== "" && (Array.isArray(v) ? v.length > 0 : true) && v !== 0)
  );
};

export const numberFormatter = (number, locale = "en-US") => {
  if (number === 0) return 0;
  if (!number) return null;

  const formattedNumber = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2
  }).format(number);

  return formattedNumber;
};

export const getSupportStatusColor = (status) => {
  if (status === "open") {
    return "#57ab5a";
  } else if (status === "fail" || status === "cancel") {
    return "red";
  } else if (status === "wait") {
    return "orange";
  } else {
    return "gray";
  }
};

export const getStatusColor = (type, status) => {
  status = status.toLowerCase();
  type = type.toLowerCase();

  switch (type) {
    case "support":
      return getSupportStatusColor(status);
  }
};
