import React from "react";
import {Dashboard} from "../pages";

const cabinetRoutes = [
    {
        path: "dashboard",
        element: <Dashboard/>,
        protected: true,
    },
];

export default cabinetRoutes;
