import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { getUserToken } from './index';
import {useContext} from "react";
import {StoreContext} from "../Providers";
import {History} from './NavigationHistoryUtils';


export const NoProtectedRoute = ({ children }) => {
    const userToken = getUserToken();
    if (userToken) return <Navigate to="/cabinet/dashboard" replace />;
    return children;
};

export const ProtectedRoute = ({ children }) => {
    const userToken = getUserToken();
    if (!userToken) return <Navigate to="/auth" replace />;
    return children;
};
