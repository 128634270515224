import React from "react";
import {Outlet} from "react-router-dom";
import {Container, CssBaseline, Grid} from "@mui/material";
import {LeftSidebar} from "../index";

function CabinetLayout() {
    return (
        <Grid container>
            <CssBaseline/>
            <Grid container item xs={12}
                  sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap:"nowrap",
                      width: "100%",
                      height: "100%",
                      minHeight: "100%",
                  }}
            >
                <Grid item
                      minWidth={'264px'}
                      width={"264px"}
                      sx={{
                          //height:'100%',
                          zIndex: 0,
                          boxShadow: "2px 0px 14px 0px rgba(0, 0, 0, 0.05)",
                      }}
                >
                    <Container style={{
                        height:'100vh',
                        position:'sticky',
                        top:"0px",
                        marginTop:0,
                        margin: 0,
                        borderRadius:0}}>
                        <LeftSidebar/>
                    </Container>
                </Grid>
                <Grid container item xs={12} style={{backgroundColor:'#f8f8f8'}}>
                        <Outlet/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CabinetLayout;
